.ant-layout {
    position: relative;
}

.ant-page-header-heading-left .ant-page-header-heading-title {
    color: #fff;
}


.logo-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.username {
    color: #fff;
}