.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  min-width: 420px;
  max-width: 450px;
}

.comment-list {
  margin-top: 10px;
}